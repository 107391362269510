module.exports = {
  siteName: "alvesmog",
  siteTitle: {
    ptBR: "Oi! Eu sou Guilherme!",
    enUS: "Hi! I'm Guilherme!",
  },
  siteDescription: `Guilherme is a Full-Stack Software Developer`,
  siteImage: "/images/overview.png",
  siteProjectsImage: "/images/projects-page.png",
  siteContactImage: "/images/contacts-page.png",
  keyWords: [
    "gatsbyjs",
    "react",
    "curriculum",
    "cv",
    "developer",
    "oracle",
    "siebel",
    "javascript",
    "node",
    "C#",
  ],
  authorName: "Guilherme Alves",
  githubUsername: "alvesmog",
  authorAvatar: "/images/avatar.jpeg",
  authorDescription: {
    ptBR: `Sou formado em engenharia e curso pós-graduação em engenharia de software. <br/>
               Sou desenvolvedor full-stack e tenho habilidades em <strong>Vanilla Javascript, NodeJS e ReactJS.</strong> <br />
               Esse website é uma forma de expor meu trabalho, e ao mesmo tempo serve como repositório para eu postar informações relevantes acerca das tecnologias que mais me interessam.`,
    enUS: `I'm an engineer and a post-grad student in software engineering. <br />
              I am a full-stack developer with strong skills in <strong>Vanilla Javascript, NodeJS e ReactJS.</strong> <br />
              This website is a way for me to showcase my work, and also serves as a repository for me to post relevant information about the technologies I'm most interested in.`,
  },
  jobs: {
    ptBR: [
      {
        company: "Softplan",
        begin: {
          month: "oct",
          year: "2020",
        },
        duration: null,
        occupation: "Desenvolvedor Pleno",
        description:
          "Desenvolvedor Front-End. Tecnologias utilizadas: ReactJS, ES6+, GraphQL (Apollo).",
      },
      {
        company: "Accenture",
        begin: {
          month: "jan",
          year: "2020",
        },
        duration: "10 meses",
        occupation: "Desenvolvedor Pleno",
        description:
          "Desenvolvimento para CRM Oracle Siebel, atendendo demandas de uma empresa de telecom. Trabalho em um ambiente ágil distribuído e uso extensivo de PL/SQL",
      },
      {
        company: "Musashi Seimitsu",
        begin: {
          month: "abr",
          year: "2018",
        },
        duration: "1 ano e 09 meses",
        occupation: "Estagiário",
        description: "Desenvolvimento em VB, Javascript e PHP",
      },
      {
        company: "Praxair, Inc",
        begin: {
          month: "mai",
          year: "2017",
        },
        duration: "10 meses",
        occupation: "Estagiário",
        description: "Desenvolvimento em VB para automatizar relatórios de KPI",
      },
    ],
    enUS: [
      {
        company: "Softplan",
        begin: {
          month: "oct",
          year: "2020",
        },
        duration: null,
        occupation: "Software Developer",
        description:
          "Front-End developer. Currently using: ReactJS, ES6+, GraphQL (Apollo).",
      },
      {
        company: "Accenture",
        begin: {
          month: "jan",
          year: "2020",
        },
        duration: "10 mos",
        occupation: "Software Engineering Analyst",
        description:
          "Oracle SIEBEL CRM application development to meet demands from a telecom company. I work on a distributed Agile (Scrum) environment and make extensive use of Oracle's PL/SQL",
      },
      {
        company: "Musashi Seimitsu",
        begin: {
          month: "apr",
          year: "2018",
        },
        duration: "1 yr and 09 mos",
        occupation: "Intern",
        description:
          "Used VBA to automate tasks. Also implemented Power BI dashboards.",
      },
      {
        company: "Praxair, Inc",
        begin: {
          month: "may",
          year: "2017",
        },
        duration: "10 mos",
        occupation: "Intern",
        description:
          "Used VBA to create programs that allowed the factory to report key performance indicators",
      },
    ],
  },
  projetos: {
    ptBR: [
      {
        image: "url('https://source.unsplash.com/random/1080x920')",
        title: "Sprint Toolkit",
        description:
          "Ferramenta que utiliza analise combinatoria para auxiliar na pontuacao User Stories",
        url: "https://alvesmog.github.io/sprinttoolkit/",
      },
      {
        image: "url('https://source.unsplash.com/random/1080x920')",
        title: "Autoquery",
        description:
          "Ambiente desenvolvido em ElectronJS para armazenas instrucoes SQL customizadas",
        url: "https://github.com/alvesmog/autoquery",
      },
    ],
    enUS: [
      {
        image: "url('https://source.unsplash.com/random/1080x920')",
        title: "Sprint Toolkit",
        description:
          "Mathematical tool that uses combinatory analysis in order to facilitate agile's user stories scoring",
        url: "https://alvesmog.github.io/sprinttoolkit/",
      },
      {
        image: "url('https://source.unsplash.com/random/1080x920')",
        title: "Autoquery",
        description:
          "SQL environment developed using ElectronJS to store custom SQL instructions",
        url: "https://github.com/alvesmog/autoquery",
      },
    ],
  },
  social: {
    linkedin: "https://www.linkedin.com/in/alvesmog",
    github: "https://github.com/alvesmog",
    email: "alvesmog@gmail.com",
  },
  siteUrl: "https://www.alvesmog.com",
  pathPrefix: "/", // Note: it must *not* have a trailing slash.
  siteCover: "/images/cover.jpeg",
  googleAnalyticsId: "UA-166775283-1",
  background_color: "#ffffff",
  theme_color: "#000000",
  fontColor: "#000000cc",
  enableDarkmode: true,
  headerLinks: {
    ptBR: [
      {
        label: "alvesmog",
        url: "/",
      },
      {
        label: "projetos",
        url: "/projetos",
      },
      {
        label: "contato",
        url: "/contato",
      },
    ],
    enUS: [
      {
        label: "alvesmog",
        url: "/",
      },
      {
        label: "projects",
        url: "/projetos",
      },
      {
        label: "contact",
        url: "/contato",
      },
    ],
  },
  technologies: [
    { label: "react", url: "/images/react-icon.png" },
    { label: "gatsby", url: "/images/gatsby-icon.png" },
  ],
  display: 'minimal-ui',
  icon: 'src/assets/alvesmog-icon.png',
}
